import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default (props) => {
  const [customerId, setCustomerId] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (to_slack) => {
    return async event => {
      if(submitted) {
        return;
      }
      setErrorMessage('');
      setSuccess('');
      event.preventDefault();
      try {
        setSubmitted(true);

        let json;
        if(customerId) {
          json = await API.post('wnAccountsAPI', '/account/sendFromCustomer', {
            headers: {
              'Content-Type': 'application/json'
            },
            body: {
              customer_id: customerId,
              to_slack,
            }
          });
        } else if(email) {
          json = await API.post('wnAccountsAPI', '/account/sendFromEmail', {
            headers: {
              'Content-Type': 'application/json'
            },
            body: {
              email: email,
              to_slack,
            }
          });
        } else {
          throw "Must enter customer id or email.";
        }
        
        if(json.error) {
          throw json.error;
        }

        console.log(json)
    
        setSubmitted(false);
        if(to_slack) {
          setSuccess("Slack message posted for customer " + json.data.email);
        } else {
          setSuccess("An email has been sent to " + json.data.email);
        }
      } catch(err) {
        console.log(err);
        setSubmitted(false);
        setErrorMessage(err);
        console.error(err);
      }
    };
  };

  return (
    <form id="EmailForm">
      <h1>Workflow Network Billing</h1>
      <p>Enter a customer ID or customer email to generate a billing portal link.</p>
      <label>Customer ID</label>
      <input value={customerId} onChange={event => setCustomerId(event.target.value)} type="text" />
      <h2>OR</h2>
      <label>Customer Email</label>
      <input value={email} onChange={event => setEmail(event.target.value)} type="text" />
      {errorMessage && <span className="error">{errorMessage}</span>}
      <label></label>
      <button onClick={handleSubmit(false)}>Send Email to Customer</button>
      <button onClick={handleSubmit(true)}>Post Link to Slack</button>
      {submitted && <div className="spinner inline"></div>}
      {success && <span className="success">{success}</span>}
    </form>
  )
}
