import React, { useState, useEffect } from 'react';
import './PaymentForm.scss';
import { API } from 'aws-amplify';
import { useLocation } from 'react-router';

export default (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [codeCurrent, setCodeCurrent] = useState();
  const location = useLocation();

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');
    const submit = async () => {
      setErrorMessage('');

      try {
        const json = await API.post('wnAccountsAPI', '/account/createPortalSession', {
          headers: {
            'Content-Type': 'application/json'
          },
          body: {
            code,
          }
        });

        if(json.error) {
          throw json.error;
        }
    
        window.location = json.data.url;
      } catch(err) {
        setErrorMessage(err);
        console.error(err);
      }
    }

    if(code && code !== codeCurrent) {
      setCodeCurrent(code);
      submit();
    }
    
  }, [codeCurrent, location]);

  return (
    <div>
      <div className="spinner"></div>
      {errorMessage && <span className="error">{errorMessage}</span>}
    </div>
  )
}
