import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default (props) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const params = useQuery();

  useEffect(() => {
    if(params.get('email')) {
      setEmail(params.get('email'));
    }
  }, [params]);

  const handleSubmit = async event => {
    setErrorMessage('');
    setSuccess(false);
    event.preventDefault();
    try {
      setSubmitted(true);
      const json = await API.post('wnAccountsAPI', '/account/sendFromEmail', {
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          email: email,
          to_slack: false,
        }
      });

      if(json.error) {
        throw json.error;
      }
  
      setSubmitted(false);
      setSuccess(true);
    } catch(err) {
      console.log(err);
      setSubmitted(false);
      setErrorMessage(err);
      console.error(err);
    }
    
  };

  return (
    <form id="EmailForm" onSubmit={handleSubmit}>
      <h1>Workflow Network Billing</h1>
      <p>We will email you a link to access your billing portal.</p>
      <label>Account email address</label>
      <input value={email} onChange={event => setEmail(event.target.value)} type="text" disabled={success || submitted} />
      {errorMessage && <span className="error">{errorMessage}</span>}
      <button disabled={success || submitted}>Submit</button>
      {submitted && <div className="spinner inline"></div>}
      {success && <span className="success">We have sent you an email with further instructions!</span>}
      {success && <span className="success-small">Be sure to check your junk folder if you didn't get an email.</span>}
    </form>
  )
}
