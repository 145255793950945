import React from 'react';
import { 
  BrowserRouter as Router, 
  Switch, 
  Route,
} from 'react-router-dom';
import './App.scss';
import PaymentForm from './PaymentForm';
import EmailForm from './EmailForm';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import CustomerForm from './CustomerForm';

// development api
if (process.env.NODE_ENV === 'development') {
  awsconfig.aws_cloud_logic_custom = awsconfig.aws_cloud_logic_custom.map(
    (config) => {
      if (config.name === 'wnAccountsAPI') {
        config.endpoint = `http://${window.location.hostname}:5000`
      }
      return config
    }
  )
}

Amplify.configure(awsconfig);

// Set up stripe
// const stripePromise = fetch('/api/getStripeKey', {
//   method: 'POST'
// }).then((response) => {
//   return response.json();
// }).then((json) => {
//   return loadStripe(json.data.stripeKey);
// });

export default () => {
  // const [customerID, setCustomerID] = useState();
  // const customerIDCookie = 'wnacc-customerID'
  // const [cookies, setCookie, removeCookie] = useCookies([customerIDCookie]);

  // // Only set customerID initially, don't update when the cookie changes
  // useEffect(() => {
  //   if(!customerID) {
  //     setCustomerID(cookies[customerIDCookie]);
  //   }
  // }, [cookies, customerID])
  
  // const updateCustomerID = ID => {
  //   setCustomerID(ID)
  //   setCookie(customerIDCookie, ID);
  // }

  // const removeCustomerID = () => {
  //   removeCookie(customerIDCookie);
  // }

  // const signOut = () => {
  //   setCustomerID('');
  //   removeCustomerID();
  // }

  return (
    <div id="App">
      {/* {customerID && <span className="signOut" onClick={signOut}>Change Email</span> } */}
      <div id="logo"></div>
      {/* <Elements stripe={stripePromise}> */}
        <Router>
          <Switch>
            <Route exact path="/portal">
              <PaymentForm />
            </Route>
            <Route exact path="/">
              <EmailForm />
            </Route>
            <Route exact path="/generate">
              <CustomerForm />
            </Route>
          </Switch>
        </Router>
      {/* </Elements> */}
    </div>
  );
}

